import React from 'react';

import { IRoomAvailabilityInstance } from '@/availability/models/room-availability.model';
import { RoomRateCode } from '@/booking/components/RoomRateCode.component';
import RoomRateCodeSelector from '@/booking/components/RoomRateCodeSelector.component';
import { useDisloyaltySelectorOptionUrl } from '@/booking/components/disloyalty-promotional-rate/hooks/useDisloyaltySelectorOptionUrl.hook';
import { getDisloyaltyTargetRate } from '@/booking/components/disloyalty-promotional-rate/utils';
import { useActiveBrandConfig } from '@/brand';
import { CurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';
import type { HotelConfiguration } from '@/hotel';
import { calculateAverageNightlyRateNet } from '@/hotel/helpers/calculate-average-nightly-rate-net';
import { useTranslation } from '@/i18n';
import { Divider } from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { Caption } from '@/ui/typography';

import { IHotelModelInstance, IStayCostInstance } from '../models';
import HotelRoomListItemDiscountedRate from './HotelRoomListItemDiscountedRate.component';
import RoomTotal from './room/RoomTotal.component';

export interface RoomRateProp {
  code: string;
  name: string;
  description?: string;
  isAvailable: boolean;
  totalWithTaxes: IFinancialAmountInstance | undefined;
  isTotalInclusiveOfAllTaxes: boolean;
  netPrice: IFinancialAmountInstance | undefined;
  includedTaxesMessageTranslationKey: HotelConfiguration['legacyTaxes']['includedTaxesMessageTranslationKey'];
  // I'm not sold on how this is implemented in this component at all...
  priceBeforeDiscount: IFinancialAmountInstance | undefined;
  // If this is true, it's deemed we're in a 'discount mode'. We should only render one, discounted, rate code.
  isDiscountRate: boolean;
  averageNightlyPrice: IFinancialAmountInstance | undefined;
  containsFees: boolean;
}

interface HotelRoomListItemRatesProps {
  roomAvailabilityModel: IRoomAvailabilityInstance;
  onSelectRoomRate: (rateCode: string) => void;
  onRequestRoomSelectionReset: () => void;
  selectedRequestRateCode: string;
  isRateSwitchingLocked: boolean;
  hotel: IHotelModelInstance;
  checkInDate: string;
  checkOutDate: string;
  updateDisloyaltyRateUrl: (url: string) => void;
}

/**
 * Either renders a rate code selection component, or a single rate if there's only one.
 * @TODO Clean up this logic. We loop through and create some weird arbitrary array (roomRates).
 * @param props
 */
export const HotelRoomListItemRates = ({
  roomAvailabilityModel,
  onSelectRoomRate,
  onRequestRoomSelectionReset,
  selectedRequestRateCode,
  isRateSwitchingLocked,
  hotel,
  checkInDate,
  checkOutDate,
  updateDisloyaltyRateUrl,
}: HotelRoomListItemRatesProps) => {
  const { hideUnavailableRoomRates, showAveragePricePerNight } =
    useActiveBrandConfig();
  const { t } = useTranslation('bookingSummary');
  const disloyaltyBannerLink = useDisloyaltySelectorOptionUrl(
    hotel.referenceId
  );
  const { legacyTaxes: taxes } = hotel;
  const roomRates = roomAvailabilityModel.availability.map(
    (stayCost: IStayCostInstance): RoomRateProp => {
      const netPrice = stayCost.getDisplayableTotalRoomRate(
        taxes.displayRateWithVat
      );

      // Fetch the rate code for the rate with the DEFAULT type ('DEFAULT' in this case means the API has included it without being asked.
      // Do not confuse this with the actual default selected rate code.
      // The DEFAULT rate type in this context is the 'before discount' rate code price).
      const priceBeforeDiscount =
        roomAvailabilityModel.rateCodeBeforeDiscount?.getDisplayableTotalRoomRate(
          taxes.displayRateWithVat
        ) ?? undefined;

      const averageNightlyRateNet = calculateAverageNightlyRateNet(
        hotel.referenceId,
        netPrice,
        checkInDate,
        checkOutDate,
        taxes
      );

      return {
        code: stayCost.rateCode.requestRateCode,
        name: stayCost.rateCode.nameOrDefault,
        description: stayCost.rateCode.descriptionOrDefault,
        isAvailable: Boolean(stayCost.bookable.value),
        netPrice,
        totalWithTaxes: stayCost.grandTotal,
        // If we're requesting advanced taxes, render a message with final total value
        isTotalInclusiveOfAllTaxes: !taxes.isAdvanced,
        includedTaxesMessageTranslationKey:
          taxes.includedTaxesMessageTranslationKey,
        priceBeforeDiscount,
        isDiscountRate: roomAvailabilityModel.isRoomAvailabilityDiscounted,
        averageNightlyPrice: !showAveragePricePerNight
          ? undefined
          : averageNightlyRateNet ?? stayCost.averageNightlyRate,
        containsFees: stayCost.containsFees,
      };
    }
  );
  const discountRate = roomRates.find((rate) => rate.isDiscountRate);

  if (discountRate) {
    if (!discountRate.netPrice) {
      throw new Error(
        `Missing netPrice from discount rate [${discountRate.code}] inside HotelRoomListItemRates`
      );
    }

    if (!discountRate.priceBeforeDiscount) {
      throw new Error(
        `Missing priceBeforeDiscount from discount rate [${discountRate.code}] inside HotelRoomListItemRates`
      );
    }

    return (
      <HotelRoomListItemDiscountedRate
        priceBeforeDiscount={discountRate.priceBeforeDiscount}
        name={discountRate.name}
        description={discountRate.description}
        isAvailable={discountRate.isAvailable}
        roomTotalElement={
          <RoomTotal
            totalWithTaxes={discountRate.totalWithTaxes}
            isTotalInclusiveOfAllTaxes={discountRate.isTotalInclusiveOfAllTaxes}
            price={discountRate.netPrice}
            includedTaxesMessageTranslationKey={
              discountRate.includedTaxesMessageTranslationKey
            }
          />
        }
      />
    );
  }

  // At this point we're stripping out all unavailable rates for brands that don't render unavailable rates.
  // TODO: Move this somewhere cleaner.
  const filteredRoomRates = hideUnavailableRoomRates
    ? roomRates.filter((rate) => rate.isAvailable)
    : roomRates;

  const roomRatesHasDisloyaltyRateTarget = filteredRoomRates.find((roomRate) =>
    getDisloyaltyTargetRate(roomRate.code)
  );

  const displayDisloyaltySelector =
    roomRatesHasDisloyaltyRateTarget && disloyaltyBannerLink;

  if (filteredRoomRates.length > 1 || displayDisloyaltySelector) {
    return (
      <RoomRateCodeSelector
        roomRates={filteredRoomRates}
        onSelectRoomRate={onSelectRoomRate}
        onRequestRoomSelectionReset={onRequestRoomSelectionReset}
        selectedRateCode={selectedRequestRateCode}
        isRateSwitchingLocked={isRateSwitchingLocked}
        hotel={hotel}
        updateDisloyaltyRateUrl={updateDisloyaltyRateUrl}
      />
    );
  }

  // Single rate
  // TODO: we should reuse insides of the RoomRateCodeSelector insides here to avoid duplication.
  if (filteredRoomRates.length > 0) {
    const singleRate = filteredRoomRates[0];
    return (
      <Stack s="xs">
        <Divider />
        <RoomRateCode
          name={singleRate.name}
          description={singleRate.description}
          isAvailable={singleRate.isAvailable}
          roomTotalElement={
            filteredRoomRates &&
            singleRate.netPrice &&
            singleRate.isTotalInclusiveOfAllTaxes !== undefined ? (
              <>
                <RoomTotal
                  totalWithTaxes={singleRate.totalWithTaxes}
                  isTotalInclusiveOfAllTaxes={
                    singleRate.isTotalInclusiveOfAllTaxes
                  }
                  price={singleRate.netPrice}
                  includedTaxesMessageTranslationKey={
                    singleRate.includedTaxesMessageTranslationKey
                  }
                />
                {singleRate.averageNightlyPrice && (
                  <Caption noWrap color="secondary300">
                    {t('labels.room.pricePerNight')}:{' '}
                    <CurrencyPreview amount={singleRate.averageNightlyPrice} />
                  </Caption>
                )}
                {singleRate.containsFees && hotel.destinationFeeSummary && (
                  <Caption color="secondary300">
                    {hotel.destinationFeeSummary}
                  </Caption>
                )}
              </>
            ) : undefined
          }
        />
      </Stack>
    );
  }

  return null;
};

export default HotelRoomListItemRates;
