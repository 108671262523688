import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';
import fr from './locale/fr.json';
import it from './locale/it.json';

export const orientExpressBrandConfig: BrandConfigurationFactory = () => ({
  chainCode: 'orientexpress',
  themeKey: 'orientexpress',
  name: 'Orient Express',
  defaultHotelCode: 'orientexpress.laminerva-rome',
  hotels: [
    {
      referenceId: 'orientexpress.laminerva-rome',
      code: 'orientexpress.laminerva-rome',
      name: 'La Minerva, Rome',
      dialect: 'ohip',
      openingDate: '2025-04-01',
    },
  ],
  homeUrl: 'https://laminerva.orient-express.com',
  domains: [
    'booking.orientexpress.local',
    'orientexpress.dev.env.ennismore.com',
    'orientexpress.staging.env.ennismore.com',
    'bookhotels.orient-express.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'it', 'fr'],
  },

  maxRoomCountPerBooking: 7,

  search: {
    errorResolutionType: 'contact-button',
  },
  hideLanguageAndCurrencySelectorInBookingManagement: true,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 2,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 4,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Privacy-Policy-EN.pdf',
        cookiePolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Cookie-Policy-EN.pdf',
        termsOfUse:
          'https://www.orient-express.com/wp-content/uploads/2024/12/Orient-Express-Hotels-Condition-of-Sales-EN.pdf',
        contact: 'https://www.orient-express.com/contact/',
      },
      it: {
        privacyPolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Privacy-Policy_IT.pdf',
        cookiePolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Cookie-Policy_IT-1.pdf',
        termsOfUse:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Hotels-General-Conditions-of-Sales-IT.pdf',
        contact: 'https://www.orient-express.com/contact/',
      },
      fr: {
        privacyPolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Politique-de-confidentialite-FR.pdf',
        cookiePolicy:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Politique-cookies-FR.pdf',
        termsOfUse:
          'https://www.orient-express.com/wp-content/uploads/2024/11/Orient-Express-Hotels-Condition-of-Sales-FR-2.pdf',
        contact: 'https://www.orient-express.com/contact/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: false,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
    it,
    fr,
  },

  analytics: {
    gtm: {
      containerId: 'GTM-PWSQD8T',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '01938ccc-690a-7e04-80e5-0937631d8261',
    },
  },
});
