import React, { useEffect } from 'react';
import { createContext, useContext } from 'react';

import { pushToDataLayer } from '@/analytics/gtm';
import { trpc } from '@/trpc/client';
import type { AppRouterInput } from '@/trpc/server/routers/app.router';

import type { ActiveExperiments, ExperimentKey } from './experiments';

const ExperimentsContext = createContext<ActiveExperiments | undefined>(
  undefined
);

export const ExperimentsProvider = (props: {
  /**
   * Flags fetched on initial render.
   */
  initialFlags?: ActiveExperiments;
  context?: AppRouterInput['flags']['activeExperiments'];

  children: React.ReactNode;
}) => {
  const { data } = trpc.flags.activeExperiments.useQuery(props.context);

  const flags = data ?? props.initialFlags;

  // Again, quick and dirty. This emits an 'experiment' event with name + status for every experiment.
  // Not using the events system here as these flags are set before the GTM event listeners are setup.
  useEffect(() => {
    if (!flags) {
      return;
    }

    if (flags) {
      for (const experimentName of Object.keys(flags)) {
        pushToDataLayer({
          event: 'experiment',
          experimentName,
          experimentStatus: flags[experimentName] as boolean,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(flags)]);

  return (
    <ExperimentsContext.Provider value={flags}>
      {props.children}
    </ExperimentsContext.Provider>
  );
};

/**
 * This is temporary - need something quick and dirty for the banner.
 * In future will use the feature flag system already in place (useFeatureSwitchStatus).
 */
export function useExperiments() {
  return useContext(ExperimentsContext);
}

export function useExperimentStatus(key: ExperimentKey) {
  return Boolean(useExperiments()?.[key]);
}
