import {
  Configuration,
  PaymentStripeBookingServiceApi,
} from '@ennismore/payment-stripe-api-client';
import { Paymentv1PaymentMethod } from '@ennismore/payment-stripe-api-client/generated-src';
import type {
  V3PrePaymentRequest,
  V3PrePaymentResponse,
} from '@ennismore/payment-stripe-api-client/generated-src';
import { z } from 'zod';

const prePaymentResponseSchema = z.object({
  clientSecret: z.string(),
  method: z.nativeEnum(Paymentv1PaymentMethod),
  created: z.string(),
});

export abstract class PaymentServiceClient {
  constructor(
    protected readonly baseUrl: string,
    protected readonly apiKey: string
  ) {}

  abstract createPaymentToken(
    request: V3PrePaymentRequest
  ): Promise<V3PrePaymentResponse>;
}

export class OpenAPIV3PaymentServiceClient extends PaymentServiceClient {
  get client() {
    return new PaymentStripeBookingServiceApi(
      new Configuration({ basePath: this.baseUrl.replace('/v2', '') })
    );
  }

  async createPaymentToken(
    request: V3PrePaymentRequest
  ): Promise<V3PrePaymentResponse> {
    // Map a domain-level StripePrePaymentRequest to v1PrePaymentRequest

    const response = await this.client.prePayment(
      {
        ...request,
        metadata: {
          ...request.metadata,
          cot: request.metadata.cot,
          flexyTime: {
            checkIn: request.metadata.flexyTime?.checkIn,
            checkOut: request.metadata.flexyTime?.checkOut,
          },
          usaState: request.metadata.usaState,
          country: request.metadata.country,
        },
      },
      {
        headers: { 'X-Api-Key': this.apiKey },
      }
    );
    return prePaymentResponseSchema.parse({
      ...response.data,
      method: response.data.method,
    });
  }
}

export const createPaymentServiceClient = (args: {
  baseUrl: string;
  apiKey: string;
}): PaymentServiceClient =>
  new OpenAPIV3PaymentServiceClient(args.baseUrl, args.apiKey);
