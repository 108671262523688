import { css } from '@emotion/react';
import React from 'react';

import { DisloyaltySelectorOption } from '@/booking/components/disloyalty-promotional-rate/DisloyaltySelectorOption.component';
import { useDisloyaltySelectorOptionUrl } from '@/booking/components/disloyalty-promotional-rate/hooks/useDisloyaltySelectorOptionUrl.hook';
import { getDisloyaltyTargetRate } from '@/booking/components/disloyalty-promotional-rate/utils';
import { CurrencyPreview } from '@/currency-preview';
import type { IFinancialAmountInstance } from '@/finance';
import { HotelConfiguration, IHotelModelInstance } from '@/hotel';
import RoomTotal from '@/hotel/components/room/RoomTotal.component';
import { useTranslation } from '@/i18n';
import { RadioButtonAccordion, RadioGroup } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { BodySmall, Caption, Text } from '@/ui/typography';

interface RoomRateSelectorItemProps {
  name: string;
  code: string;
  description?: string | React.ReactNode;
  isAvailable: boolean;
  totalWithTaxes: IFinancialAmountInstance | undefined;
  isTotalInclusiveOfAllTaxes: boolean | undefined;
  netPrice: IFinancialAmountInstance | undefined;
  includedTaxesMessageTranslationKey: HotelConfiguration['legacyTaxes']['includedTaxesMessageTranslationKey'];
  averageNightlyPrice: IFinancialAmountInstance | undefined;

  isSelected: boolean;

  onSelect: () => void;
  hotel: IHotelModelInstance;
  containsFees: boolean;
}

const RoomRateCodeSelectorOption: React.FC<RoomRateSelectorItemProps> = (
  props
) => {
  const { colors, keylines, forms, componentProperties } = useTheme();
  const { t } = useTranslation('bookingSummary');
  return (
    <div
      css={css`
        margin-bottom: 4px;
        border-top: ${forms.radioGroup?.divider?.border ??
        keylines?.border ??
        `1px solid ${colors.primary300}`};
        padding-top: 4px;
        &:last-child {
          margin-bottom: 0;
        }
      `}
    >
      <RadioButtonAccordion
        onClick={props.onSelect}
        isChecked={props.isSelected}
        isDisabled={!props.isAvailable}
        collapsibleContent={
          <>
            {props.averageNightlyPrice && (
              <>
                <Caption noWrap color="secondary300">
                  {t('labels.room.pricePerNight')}:{' '}
                  <CurrencyPreview amount={props.averageNightlyPrice} />
                </Caption>
                <Spacer s="xs" />
              </>
            )}
            {props.containsFees && props.hotel.destinationFeeSummary && (
              <>
                <Caption color="secondary300">
                  {props.hotel.destinationFeeSummary}
                </Caption>
                <Spacer s="xs" />
              </>
            )}
            {props.description && (
              <BodySmall
                color="secondary300"
                // TODO: @hardcodedstrings
                aria-label={`Rate description: ${props.description}`}
                css={css(forms.radioButton?.description)}
              >
                {props.description}
              </BodySmall>
            )}
          </>
        }
        testId={props.code}
        value={props.code}
      >
        <Text
          style="heading6"
          // TODO: @hardcodedstrings
          aria-label={`Selected rate: ${props.name}.`}
          css={{
            ...componentProperties.bedroomCard?.roomRateName,
            [':first-letter']:
              componentProperties.bedroomCard?.roomRateName?.firstLetter,
          }}
        >
          {/* A hack making sure capitalization is applied on a lowercased string */}
          {/* TODO: we should transform to lowercase in all cases and solidify casing per brand via css */}
          {componentProperties.bedroomCard?.roomRateName?.textTransform ===
          'capitalize'
            ? props.name.toLowerCase()
            : props.name}
        </Text>
        <span>
          {props.netPrice && props.isTotalInclusiveOfAllTaxes !== undefined ? (
            <RoomTotal
              totalWithTaxes={props.totalWithTaxes}
              isTotalInclusiveOfAllTaxes={props.isTotalInclusiveOfAllTaxes}
              price={props.netPrice}
              includedTaxesMessageTranslationKey={
                props.includedTaxesMessageTranslationKey
              }
            />
          ) : undefined}
        </span>
      </RadioButtonAccordion>
    </div>
  );
};

interface RoomRateCodeSelectorProps {
  roomRates: Omit<
    RoomRateSelectorItemProps,
    'onSelect' | 'isSelected' | 'hotel'
  >[];

  onSelectRoomRate: (code: string) => void;
  onRequestRoomSelectionReset: () => void;
  selectedRateCode: string | undefined;
  isRateSwitchingLocked: boolean;
  hotel: IHotelModelInstance;
  updateDisloyaltyRateUrl: (url: string) => void;
}

export const RoomRateCodeSelector = (props: RoomRateCodeSelectorProps) => {
  const selectedRate = props.roomRates.find(
    (rate) => rate.code === props.selectedRateCode
  );

  const disloyaltyBannerLink = useDisloyaltySelectorOptionUrl(
    props.hotel.referenceId
  );
  return (
    <RadioGroup
      defaultValue={props.selectedRateCode}
      // TODO: @hardcodedstrings
      aria-label="Rates"
    >
      {props.isRateSwitchingLocked && selectedRate ? (
        <RoomRateCodeSelectorOption
          {...selectedRate}
          isSelected={true}
          onSelect={() => {
            // do nothing, we can't switch
          }}
          isAvailable={true}
          hotel={props.hotel}
        />
      ) : (
        <>
          {props.roomRates.map((rate) => {
            if (getDisloyaltyTargetRate(rate.code) && disloyaltyBannerLink) {
              return (
                <>
                  <RoomRateCodeSelectorOption
                    {...rate}
                    key={rate.code}
                    isSelected={rate.code === props.selectedRateCode}
                    onSelect={() => props.onSelectRoomRate(rate.code)}
                    hotel={props.hotel}
                  />
                  <DisloyaltySelectorOption
                    onSelectRoomRate={props.onSelectRoomRate}
                    selectedRateCode={props.selectedRateCode}
                    updateDisloyaltyRateUrl={props.updateDisloyaltyRateUrl}
                    hotelId={props.hotel.referenceId}
                    link={disloyaltyBannerLink}
                  />
                </>
              );
            }
            return (
              <RoomRateCodeSelectorOption
                {...rate}
                key={rate.code}
                isSelected={rate.code === props.selectedRateCode}
                onSelect={() => props.onSelectRoomRate(rate.code)}
                hotel={props.hotel}
              />
            );
          })}
        </>
      )}
    </RadioGroup>
  );
};

export default RoomRateCodeSelector;
