import React from 'react';

import { CookieConsentProviderConfig } from '@/cookie-consent/config';
import { ConsentManagerSideEffects } from '@/cookie-consent/providers/ConsentManager.component';
import { OneTrustSideEffects } from '@/cookie-consent/providers/OneTrust.component';

export const CookieConsentSideEffects = ({
  config,
}: {
  config: CookieConsentProviderConfig;
}) => {
  switch (config.provider) {
    case 'onetrust':
      return <OneTrustSideEffects id={config.oneTrustId} />;
    case 'consent-manager':
      return <ConsentManagerSideEffects id={config.accountId} />;
  }
};
