import { z } from 'zod';

import { AddressModel } from '@/common/models/address.model';

export type IProfile = typeof ProfileModel;
export type IProfileInstance = z.infer<IProfile>;
export type IProfileSnapshot = z.input<IProfile>;

export const ProfileModel = z
  .object({
    id: z.string(),
    firstName: z.string().optional(),
    // TODO: This could be temporary - making this optional,
    // as the API occasionally returns null for this field (swagger thinks it's required)
    lastName: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().optional(),
    language: z.string().default('en'),
    address: AddressModel.optional(),
    type: z.enum(['GUEST', 'BILLING']).default('BILLING'),
  })
  .transform((self) => ({
    ...self,
    get fullName() {
      return [self.firstName, self.lastName].filter(Boolean).join(' ');
    },
  }));
