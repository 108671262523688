import { V1Guest } from '@ennismore/ows-api-client';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useActiveBrandConfig } from '@/brand';
import { getCountFromBookingGuest } from '@/hotel';
import { useTranslation } from '@/i18n';
import { Select, getOptionsFromMaxCount } from '@/ui/form';

import type { SearchFormFields } from '../validators/search-form.validator';

export const ChildAgeSelectInput = ({
  label,
  name,
  index,
}: {
  label: string;
  name: `roomOccupancy.${number}.value`;
  index: number;
}) => {
  const { maxChildAge } = useActiveBrandConfig();
  const { t } = useTranslation('additionalInformation');

  const childAgeOptions = getOptionsFromMaxCount(maxChildAge, true);

  // TODO: provide translations
  childAgeOptions[0] = {
    ...childAgeOptions[0],
    label: t('additionalChildBed.form.labels.under1Year'),
  };

  const localName = `roomOccupancy.${index}.childrenAges` as const;

  const {
    control,
    register,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useFormContext<Pick<SearchFormFields, 'roomOccupancy' | 'roomCount'>>();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: localName,
  });
  const [roomOccupancy, childAge] = watch([name, localName]);

  const roomOccupancyIncludesChildren =
    roomOccupancy && roomOccupancy !== V1Guest.Unspecified
      ? getCountFromBookingGuest(roomOccupancy).children > 0
      : false;

  useEffect(() => {
    // gets the current number of children or if none specified sets it to 0
    const value =
      roomOccupancy !== '' && roomOccupancy !== V1Guest.Unspecified
        ? getCountFromBookingGuest(roomOccupancy)
        : { children: 0 };
    // calculates the number of fields needed based on the number of children specified
    // we then populate the fields with their previous values if they exist or set the values to an empty string
    const replaceValue = Array.from({ length: value.children }).map(
      (_, localIndex) => {
        return {
          value:
            getValues().roomOccupancy[index].childrenAges[localIndex]?.value ??
            '',
        };
      }
    );

    if (replaceValue.length) {
      replace(replaceValue);
    } else {
      remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomOccupancy]);

  return (
    <>
      {roomOccupancyIncludesChildren &&
        fields.map((field, fieldIndex) => (
          <div key={field.id}>
            <Select
              label={label}
              value={childAge[fieldIndex]?.value}
              error={
                errors?.roomOccupancy?.[index]?.childrenAges?.[fieldIndex]
                  ?.value?.message
              }
              isRequired
              options={childAgeOptions}
              {...register(
                `roomOccupancy.${index}.childrenAges.${fieldIndex}.value`,
                {
                  onBlur: () =>
                    trigger(
                      `roomOccupancy.${index}.childrenAges.${fieldIndex}`
                    ),
                  onChange: () =>
                    trigger(
                      `roomOccupancy.${index}.childrenAges.${fieldIndex}`
                    ),
                }
              )}
            />
          </div>
        ))}
    </>
  );
};
