import React from 'react';

import { useSpaceMatchingKey, useTheme } from '@/ui/theme';

interface SidebarSectionProps {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

export const SidebarSection = (props: SidebarSectionProps) => {
  const { colors, componentProperties, keylines } = useTheme();
  const secondaryCardStyle = componentProperties.sidebarCards?.secondary;
  const paddingSpace = useSpaceMatchingKey(
    componentProperties.sidebarCards?.secondary?.padding ?? 'xs'
  );

  // This is a bit of a mess - but hopefully new format a little clearer
  // Let's cleanup when we re-work this.
  // First, take the border from the keylines config:
  let border = keylines?.border;
  // If that's missing, use a standard border with the border color
  if (!border && secondaryCardStyle?.borderColor) {
    border = `1px solid ${colors[secondaryCardStyle.borderColor]}`;
  }
  // Lastly, hide the border if the "hide" flag is enabled
  if (secondaryCardStyle?.hideBorder) {
    border = 'none';
  }

  return (
    <section
      className={props.className}
      css={{
        background: colors[secondaryCardStyle?.backgroundColor ?? 'background'],
        border,
        ...secondaryCardStyle,
        padding: paddingSpace,
      }}
      data-test={props.testId}
    >
      {props.children}
    </section>
  );
};
