import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
// i18n
import da from 'dayjs/locale/da';
import de from 'dayjs/locale/de';
import type { Locale } from 'dayjs/locale/en';
import enUS from 'dayjs/locale/en';
import enGB from 'dayjs/locale/en-gb';
import es from 'dayjs/locale/es';
import fr from 'dayjs/locale/fr';
import it from 'dayjs/locale/it';
import nl from 'dayjs/locale/nl';
import ptPT from 'dayjs/locale/pt';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
// Timezones
import utc from 'dayjs/plugin/utc';

import { API_DATE_FORMAT } from '@/api';
import { SupportedLocale } from '@/i18n';

import { createArrayOfLength } from './array';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const DAYJS_LOCALES: { [locale in SupportedLocale]: Locale } = {
  'en-GB': enGB,
  'en-US': enUS,
  fr,
  it,
  de,
  da,
  es,
  nl,
  'pt-PT': ptPT,
};

const getDayjsLocale = (localeKey: string): Locale => DAYJS_LOCALES[localeKey];

export const setDayjsLocale = (key: string) => {
  dayjs.locale({
    ...getDayjsLocale(key),
    weekStart: 1,
  });
};

/**
 * Returns an array of the days within the week of the date supplied.
 * For example: Jan 28th 2020 is a Tuesday of week beginning the 27th.
 * So, getWeekDays('2020-01-28') returns ['2020-01-27', '2020-01-28', '2020-01-30', '2020-01-31', '2020-02-01', '2020-02-02', '2020-02-03']
 * @param date
 */
export const getWeekDays = (date: string) => {
  const startDate = dayjs(date).startOf('week');
  return createArrayOfLength(7).map((index) =>
    startDate.add(index, 'd').format(API_DATE_FORMAT)
  );
};

/**
 * Is date1 before date2?
 * @param date1
 * @param date2
 */
export const isBeforeDate = (
  date1: string | dayjs.Dayjs,
  date2: string | dayjs.Dayjs
) => dayjs(date1).isBefore(date2, 'd');

export const diffInDays = (date1: string, date2: string): number =>
  Math.max(dayjs(date2).diff(date1, 'day'), 0);

export const getNumberOfNightsBetween = (startDate: string, endDate: string) =>
  Math.abs(dayjs(startDate).diff(endDate, 'd'));

export const formatUTCDate = (date: Date | string | Dayjs, format: string) =>
  dayjs(date).tz('Etc/UTC', false).format(format);

/**
 * A helper function that formats date into an accessible date string (one that has no shortened parts).
 * @param dateString YYYY-MM-DD (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format)
 * @returns e.g. `17 November 2023` (en-GB), `November 17, 2023` (en-US)
 */
export const getAccessibleDateString = (
  dateString: string,
  locale: Intl.Locale
) =>
  getLocalisedDateString(dateString, locale, {
    month: 'long',
    weekday: 'long',
  });

/**
 * A helper function that localises a given date, and includes weekday.
 * @param dateString YYYY-MM-DD (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format)
 * @returns e.g. `Wed, 10 Jan 2023` (en-GB), `Wed, Jan 15, 2023` (en-US)
 */
export const getFullLocalisedDateString = (
  dateString: string,
  locale: Intl.Locale
) =>
  getLocalisedDateString(dateString, locale, {
    weekday: 'short',
  });

/**
 * A helper function that localises a given date.
 * @param dateString YYYY-MM-DD (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format)
 * @returns e.g. `10 Jan 2023` (en-GB), `Jan 15, 2023` (en-US)
 */
export const getLocalisedDateString = (
  dateString: string,
  locale: Intl.Locale,
  options?: Intl.DateTimeFormatOptions
) =>
  // By appending 'T00:00' we're telling JavaScript to interpret the date string as being in the local time zone rather than UTC.
  new Date(`${dateString}T00:00`).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    ...options,
  });
