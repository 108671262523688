import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'adobeCaslonPro, sans-serif',
  intro: 'p22Underground, Times',
  subheader: 'p22Underground, sans-serif',
  body: 'p22Underground, sans-serif',
};

export const delanoTheme: EnnismoreTheme = {
  themeKey: 'delano',
  logoUrl: '/brands/delano/images/logo.svg',
  faviconUrl: '/brands/delano/icons/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '32px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '26px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '24px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '20px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '20px',
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#2E2F29',
    textSecondary: '#2E2F29',
    background: '#F2ECE6',
    primary100: '#FEF9F3',
    primary200: '#E9E0D5',
    primary300: '#F0E4D7',
    primary400: '#FFE7D9',
    primary500: '#FF9272',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#A9A9A4',
    secondary400: '#3B423F',
    error: '#B00020',
  },
  buttons: {
    primary: {
      fontFamily: fonts.body,
      fontWeight: 300,
      backgroundColor: '#534645',
      borderRadius: 0,
      color: '#FDFCFB',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
      fontSize: '12px',
      lineHeight: '24px',
    },
    secondary: {
      borderRadius: '0px',
      fontFamily: fonts.body,
      fontWeight: 300,
      backgroundColor: 'transparent',
      color: '#3D3937',
      border: '1px solid #3D3937',
      letterSpacing: '0.6px',
    },
  },
  forms: {
    heading: {
      fontSize: '24px',
    },
    input: {
      backgroundColor: 'white',
      fontSize: '16px',
    },
    radioButton: {
      description: {
        color: '#2E2F29',
        padding: '8px 0 0 0',
      },
      collapsibleContent: {
        padding: '8px 0 0 0',
      },
      padding: '21px 8px 14px',
      selected: {
        backgroundColor: 'white',
        padding: '21px 8px 30px',
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'adobeCaslonPro',
      fontWeight: 400,
      sources: {
        woff2:
          'https://use.typekit.net/af/3cbd9b/000000000000000000012d68/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      },
      externalImport:
        'https://p.typekit.net/p.css?s=1&k=eui3fun&ht=tk&f=5624.8482&a=167456945&app=typekit&e=css',
    },
    {
      fontFamily: 'p22Underground',
      fontWeight: 400,
      sources: {
        woff2:
          'https://use.typekit.net/af/ad8f50/00000000000000000000de3b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      },
      externalImport:
        'https://p.typekit.net/p.css?s=1&k=eui3fun&ht=tk&f=5624.8482&a=167456945&app=typekit&e=css',
    },
    {
      fontFamily: 'p22UndergroundLight',
      fontWeight: 300,
      sources: {
        woff2:
          'https://use.typekit.net/af/3cbd9b/000000000000000000012d68/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
      },
      externalImport:
        'https://p.typekit.net/p.css?s=1&k=eui3fun&ht=tk&f=5624.8482&a=167456945&app=typeki&e=css',
    },
  ],
  keylines: {
    border: '1px solid #A9A9A4',
  },
  componentProperties: {
    header: {
      backgroundColor: '#E9E0D5',
      borderBottom: 'none',
      logo: {
        borderRight: 'none',
      },
      height: 90,
      wizard: {
        step: {
          textTransform: 'uppercase',
          fontSize: '12px',
          title: {
            padding: '15px 0 0',
          },
        },
      },
    },
    bookingManagement: {
      bookingCard: {
        borderRadius: '0px',
        border: 'none',
        cancelled: { opacity: 1 },
        header: {
          backgroundColor: 'white',
          textTransform: 'none',
          borderBottom: '1px solid #A9A9A4',
          height: '78px',
        },
        body: {
          padding: '32px 24px',
          rowGap: 'l',
        },
        footer: {
          borderTop: '1px solid #A9A9A4',
        },
        button: {
          variant: 'secondary',
        },
        cancellationModal: {
          padding: '80px 124px',
          borderRadius: '0px',
          textAlign: 'center',
          maxWidth: '65ch',
          mobile: {
            padding: '40px 16px 60px',
            title: {
              fontSize: '26px',
            },
          },
          title: {
            fontSize: '32px',
            lineHeight: '32px',
            letterSpacing: '0.64px',
          },
        },
      },
    },
    calendar: {
      border: '1px solid #A9A9A4',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      header: {
        backgroundColor: '#F7F3ED',
        nav: {
          hover: {
            backgroundColor: '#DECACE',
          },
        },
        caption: {
          fontFamily: fonts.headline,
          letterSpacing: '0.9px',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '38px',
          marginTop: '6px',
        },
        arrow: {
          stroke: '#A9A9A4',
          top: '-1px',
        },
      },
      body: {
        date: {
          selected: {
            backgroundColor: '#534645',
          },
          selectedMiddle: {
            backgroundColor: '#DECACE',
          },
        },
      },
    },
    modals: {
      title: { fontSize: '32px' },
    },
    cards: {
      primary: {
        border: 'none',
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    staySummary: {
      border: 'none',
      borderRadius: 0,
      title: {
        fontSize: '24px',
      },
      dataRow: {
        label: {
          fontWeight: 300,
        },
      },
      dropdown: {
        textTransform: 'uppercase',
        fontSize: '12px',
      },
      summarySection: {
        padding: '36px 24px',
      },
      roomNumberHeading: {
        padding: '0 0 18px',
      },
    },
    confirmation: {
      page: {
        background: '#989D7B',
      },
      header: {
        backgroundColor: '#F2ECE6',
        paddingBottom: '20px',
      },
      headerImage: {
        height: '480px',
        objectFit: 'cover',
        container: {
          margin: '0',
          width: '100%',
          paddingBottom: '48px',
        },
      },
      bookingConfirmationContainer: {
        margin: '60px auto auto auto',
        mobile: {
          margin: '12px auto auto',
        },
      },
      bookingConfirmationDetailsCard: {
        customerNameHeading: {
          fontFamily: fonts.headline,
          fontSize: '18px',
        },
        manageMyBookingLink: {
          textTransform: 'uppercase',
          fontSize: '12px',
        },
      },
      restaurantsCard: {
        restaurantTitle: {
          fontSize: '24px',
        },
      },
      whereToFindUsCard: {
        planYourJourneyLink: {
          color: '#2E2F29',
        },
      },
    },
    page: {
      background: '#F2ECE6',
    },
    footer: {
      backgroundColor: '#2E2F29',
      color: '#E9E0D5',
    },
    bedroomCard: {
      roomTitle: {
        fontSize: '24px',
        textTransform: 'capitalize',
      },
      roomRateName: {
        fontSize: '20px',
        textTransform: 'capitalize',
        marginTop: '3px',
      },
      roomSpecItem: {
        color: '#A9A9A9',
        value: {
          color: '#2E2F29',
        },
      },
      description: {
        padding: '14px 0 18px',
      },
      mobile: {
        padding: '0 16px 28px',
      },
      totalPrice: {
        mobile: {
          fontSize: '24px',
        },
      },
    },
    imageSlider: {
      paginator: { line: { margin: '9px 10px 0 10px' } },
    },
    search: {
      collapsibleForm: {
        searchSummary: {
          modifySearchButton: {
            textTransform: 'uppercase',
            fontSize: '12px',
            letterSpacing: '1px',
          },
          copy: {
            marginBottom: '18px',
          },
          mobile: {
            title: {
              fontSize: '16px',
            },
          },
        },
      },
      results: {
        sidebarContainer: {
          hotelPerks: {
            border: 0,
            mobile: {
              modal: {
                backgroundColor: 'white',
              },
              button: {
                textTransform: 'uppercase',
              },
            },
          },
          cancellationPolicy: {
            color: '#2E2F29',
            mobile: {
              textTransform: 'uppercase',
              color: '#2E2F29',
              fontSize: '12px',
            },
          },
        },
        bedroomAvailabilityList: {
          rowGap: '40px',
        },
      },
    },
    sidebarCards: {
      primary: {
        borderRadius: '0px',
      },
      secondary: {
        borderRadius: '6px',
        borderColor: 'primary300',
      },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
