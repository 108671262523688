import type {
  V3PrePaymentRequest,
  V3PrePaymentResponse,
} from '@ennismore/payment-stripe-api-client/generated-src';
import { useMutation } from 'react-query';

import { useEnnismoreApiClientConfig } from '@/api';
import type { BrandConfiguration } from '@/brand';
import { mapNetworkError } from '@/errors';

import { CreatePaymentTokenError } from '../errors/create-payment-token.error';
import { createPaymentServiceClient } from '../services/payment.service';

export const useCreatePaymentTokenMutation = (
  marketingConsentMode: BrandConfiguration['marketingConsentMode']
) => {
  const clientConfig = useEnnismoreApiClientConfig();

  const createToken = async (
    args: V3PrePaymentRequest
  ): Promise<V3PrePaymentResponse> => {
    const client = createPaymentServiceClient({
      baseUrl: clientConfig.baseUrl,
      apiKey: clientConfig.apiKey,
    });

    /**
     * This is a hack to support Gleneagles/Townhouse's opt-out strategy for marketing emails.
     * In future, we should be flipping this value at the form-level. Awaiting future formatting functionality coming in formik@3.0.
     */
    const { marketingOptIn: originalMarketingOptIn } = args.metadata;
    const marketingOptIn =
      marketingConsentMode === 'opt-out'
        ? !originalMarketingOptIn
        : originalMarketingOptIn;

    try {
      return client.createPaymentToken({
        ...args,
        metadata: { ...args.metadata, marketingOptIn },
      });
    } catch (error) {
      throw new CreatePaymentTokenError(await mapNetworkError(error));
    }
  };

  return useMutation<
    V3PrePaymentResponse,
    CreatePaymentTokenError,
    V3PrePaymentRequest
  >(createToken);
};
