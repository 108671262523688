import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { useActiveBrandConfig } from '@/brand';
import { useTranslation } from '@/i18n';

import { BrandLogoButton, LanguageAndCurrencySelector } from '../controls';
import { useTheme } from '../theme';

interface HeaderProps {
  children?: React.ReactNode;
  hideLanguageAndCurrencySelector?: boolean;
  className?: string;
}

export const Header = (props: HeaderProps) => {
  // TODO: Remove brand reference here. UI package should be agnostic.
  const { name: brandName, homeUrl } = useActiveBrandConfig();
  const {
    colors,
    fonts,
    keylines,
    componentProperties: { header: headerStyles },
  } = useTheme();
  const { t } = useTranslation('common');

  return (
    <header
      className={props.className}
      css={css`
        border-bottom: ${headerStyles?.borderBottom ??
        keylines?.border ??
        `1px solid ${colors.primary300}`};
        position: sticky;
        top: 0;
        z-index: 1200;
        background-color: ${headerStyles?.backgroundColor ?? colors.background};
        padding: ${headerStyles?.padding ?? 0};
        box-shadow: ${headerStyles?.boxShadow ?? 'none'};

        .logo {
          position: absolute;
          width: 192px;
          height: ${headerStyles?.height ?? '60'}px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: ${headerStyles?.logo?.borderRight ??
          keylines?.border ??
          `1px solid ${colors.primary300}`};
          align-self: flex-start;
          z-index: 1250;
          left: ${headerStyles?.logo?.left ?? '0'};

          @media all and (max-width: 920px) {
            left: 0;
          }
          @media all and (max-width: 730px) {
            display: ${headerStyles?.logo?.mobile?.display ?? 'none'};
          }
        }

        .Wizard {
          flex: 1;
          min-height: ${headerStyles?.height ?? '60'}px;
        }

        .LanguageAndCurrencySelector {
          display: flex;
          position: absolute;
          right: 30px;

          @media all and (max-width: 730px) {
            right: 25px;
          }
        }
      `}
    >
      <a
        href="#maincontent"
        css={css`
          background: #e9e9e9;
          padding: 8px;
          position: absolute;
          left: -9999px;
          color: black;
          font-family: ${fonts.body};
          display: block;
          width: 1px;
          height: 1px;

          &:focus {
            position: static;
            width: auto;
            height: auto;
          }
        `}
      >
        {t('skipToMain')}
      </a>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: ${headerStyles?.margin ?? '0'};
          max-width: ${headerStyles?.maxWidth ?? '100%'};
        `}
      >
        <BrandLogoButton
          brandHomeUrl={homeUrl}
          label={t(`returnToHome`, { brandName })}
          analyticsLocation="Header"
        />
        <div className="Wizard">{props.children}</div>
        {!props.hideLanguageAndCurrencySelector && (
          <div className="LanguageAndCurrencySelector">
            <LanguageAndCurrencySelector />
          </div>
        )}
      </div>
    </header>
  );
};

export const HeaderNav = ({
  alignContent,
  children,
  className,
}: {
  alignContent?: 'left' | 'center';
  children?: React.ReactNode;
  className?: string;
}) => {
  const {
    componentProperties: { header },
  } = useTheme();

  const Nav = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${header?.height ?? '60'}px;
    min-height: ${header?.height ?? '60'}px;
    ${alignContent === 'left' ? 'width: 192px' : 'flex: 1'}
  `;

  return <Nav className={className}>{children}</Nav>;
};
