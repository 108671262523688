import { css } from '@emotion/react';
import React, { AriaAttributes, CSSProperties } from 'react';

import { useFeatureSwitchStatus } from '@/features';

import { EnnismoreTheme, EnnismoreThemeColors, useTheme } from '../theme';

type GenericTextProps = {
  children?: React.ReactNode | string;
  style: keyof EnnismoreTheme['text'];
  color?: EnnismoreThemeColors;
  inheritColor?: boolean;
  fontFamily?: CSSProperties['fontFamily'];
  opacity?: CSSProperties['opacity'];
  textAlign?: 'left' | 'center' | 'right';
  inline?: boolean;
  uppercase?: boolean;
  fontStyle?: 'italic' | 'normal';
  noWrap?: boolean;
  role?: string;
  testId?: string;
  id?: string;
  className?: string;
} & AriaAttributes;

type SpecificTextProps = Omit<GenericTextProps, 'style'>;

export const Text = ({
  children,
  style,
  color,
  inheritColor,
  fontFamily,
  opacity,
  textAlign,
  inline,
  uppercase,
  fontStyle,
  noWrap,
  testId,
  ...rest
}: GenericTextProps) => {
  const { colors, text } = useTheme();
  const textStyles = text[style];
  const debugTextStyle = useFeatureSwitchStatus('debug-text');

  return (
    <span
      css={css({
        ...textStyles,
        fontFamily: fontFamily ?? textStyles?.fontFamily,
        color: inheritColor
          ? 'inherit'
          : color
          ? colors[color]
          : colors.textPrimary,
        opacity,
        a: { textDecoration: 'underline' },
        textAlign,
        display: inline ? 'inline' : 'block',
        fontStyle: fontStyle || 'normal',
        whiteSpace: noWrap ? 'nowrap' : 'normal',
        position: debugTextStyle ? 'relative' : undefined,
        textTransform: uppercase ? 'uppercase' : textStyles?.textTransform,
        sup: {
          fontSize: '0.75em',
          position: 'relative',
          top: '-0.5em',
        },
      })}
      data-testid={testId}
      aria-live={rest['aria-live']}
      aria-label={rest['aria-label']}
      className={rest.className}
      role={rest.role}
      id={rest.id}
    >
      {/* When the feature flag for debugging text styles is toggled on, display the style tag.
      This is used by designers for the further development of the theming strategy. */}
      {debugTextStyle ? (
        <span
          css={css`
            position: absolute;
            color: white;
            background: black;
            top: 0;
            left: 0;
            font-size: 10px;
            opacity: 0.75;
            height: 13px;
            line-height: 8px;
          `}
        >
          {style}
        </span>
      ) : undefined}
      {children}
    </span>
  );
};

export default Text;

export const BodyExtraSmall = (props: SpecificTextProps) => (
  <Text style="bodyExtraSmall" {...props}></Text>
);

export const BodySmall = (props: SpecificTextProps) => (
  <Text style="bodySmall" {...props}></Text>
);

export const BodyLarge = (props: SpecificTextProps) => (
  <Text style="bodyLarge" {...props}></Text>
);

export const BodyCopy = (props: SpecificTextProps) => (
  <Text style="bodyCopy" {...props} />
);

export const Caption = (props: SpecificTextProps) => (
  <Text style="captions" {...props} />
);

type HeadingProps = GenericTextProps & {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Heading = (props: HeadingProps) => {
  const HeadingTag = props.as;
  return (
    <HeadingTag
      aria-label={props['aria-label']}
      style={{ display: props.inline ? 'inline' : 'block' }}
    >
      <Text {...props}></Text>
    </HeadingTag>
  );
};
