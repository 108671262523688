import css from '@emotion/css';
import React from 'react';

import { useBookingCheckout } from '@/booking-checkout';
import { SummarySectionContainer } from '@/booking/components/SummarySectionContainer';
import { IRoomStayInstance } from '@/booking/models';
import { useActiveBrandConfig } from '@/brand';
import { getFullLocalisedDateString } from '@/common/utils/date';
import { DataTable, DataTableRow } from '@/core/components';
import { CurrencyPreview } from '@/currency-preview';
import { useHotel } from '@/hotel';
import { useLocale, useTranslation } from '@/i18n';
import { useSpaceMatchingKey, useTheme } from '@/ui/theme';
import { BodySmall, Heading } from '@/ui/typography';

import { useBooking } from '../hooks';
import { BookingRequest } from '../interfaces';
import TaxTotals from './TaxTotals.component';

interface BookingBreakdownPrimaryTableProps extends BookingRequest {
  showCheckInTimes?: boolean;
  showTotalNightCount?: boolean;
}

/**
 * The core information about a booking, in table row format.
 */
export const BookingBreakdownPrimaryTable: React.FC<
  BookingBreakdownPrimaryTableProps
> = (props) => {
  const booking = useBooking(props);
  const hotel = useHotel(props.hotelSlug);
  const { t } = useTranslation('bookingSummary');

  const locale = useLocale();

  if (!booking || !hotel) {
    return null;
  }

  return (
    <DataTable>
      {!booking.firstRoomStay.mainGuestIsPaying ? (
        <DataTableRow
          label={t('labels.mainGuest')}
          value={booking.firstRoomStay.mainGuestProfile.fullName}
          testId="main-guest-name"
        />
      ) : null}
      <DataTableRow
        label={t('labels.where')}
        value={hotel.name}
        testId="hotel-name"
      />
      <DataTableRow
        label={t('labels.checkInDate')}
        value={getFullLocalisedDateString(booking.firstRoomStay.from, locale)}
        testId="check-in-date"
      />
      <DataTableRow
        label={t('labels.checkOutDate')}
        value={getFullLocalisedDateString(booking.firstRoomStay.to, locale)}
        testId="check-out-date"
      />

      {props.showTotalNightCount && (
        <DataTableRow
          label={t('labels.nightCount')}
          value={booking.totalNumberOfNights}
          testId="night-count"
        />
      )}

      {props.showCheckInTimes && (
        <>
          <DataTableRow
            label={t('labels.checkInTime')}
            value={t('values.checkInTime', {
              time: booking.checkInAfter ?? hotel.operational.checkIn,
            })}
            testId="check-in-time"
          />
          <DataTableRow
            label={t('labels.checkOutTime')}
            value={t('values.checkOutTime', {
              time: booking.checkOutBefore ?? hotel.operational.checkOut,
            })}
            testId="check-out-time"
          />
        </>
      )}

      {booking.dog && (
        <DataTableRow testId="dog" label={t('labels.dogs')} value="1 Dog" />
      )}
      {booking.childBedTypeText && (
        <DataTableRow
          label={t('labels.childBed')}
          value={`1 ${booking.childBedTypeText}`}
          testId="child-bed"
        />
      )}
    </DataTable>
  );
};

interface BookingBreakdownRoomsTableProps extends BookingRequest {
  separator?: React.ReactNode;
  pricePerNightMode: 'actual' | 'average';
  displayConfirmationNumber: boolean;
  wrapElementsInContainer: boolean;
  className?: string;
}

/**
 * A list of rooms attached to the given booking, grouped and headed by sequential room number.
 */
export const BookingBreakdownRoomsTable: React.FC<
  BookingBreakdownRoomsTableProps
> = (props) => {
  const booking = useBooking(props);
  const hotel = useHotel(props.hotelSlug);
  const {
    config: { additionalInfoFields },
  } = useBookingCheckout(props.bookingId);
  const { t } = useTranslation('bookingSummary');
  const { t: getRoomOccupancyTypeTranslation } =
    useTranslation('roomOccupancyTypes');
  const { componentProperties } = useTheme();
  const brandConfig = useActiveBrandConfig();

  const { showRoomTotalInBreakdown } = useActiveBrandConfig();

  if (!booking || !hotel) {
    return null;
  }

  // Either comes from the booking, or if we haven't checked out yet, grab this from the local additional info form
  const isSpecialAssistanceRequested: boolean =
    booking.isSpecialAssistanceRequested ||
    Boolean(additionalInfoFields?.accessRequirements);

  const isMultiRoomBooking = booking.roomStay.length > 1;
  const Container = props.wrapElementsInContainer
    ? SummarySectionContainer
    : React.Fragment;
  return (
    <>
      {booking.roomStay.map((stay, index) => (
        <React.Fragment key={index}>
          <Container>
            <div className={props.className}>
              {isMultiRoomBooking ? (
                <Heading
                  as="h6"
                  style="heading6"
                  css={{
                    paddingBottom: '24px',
                    ...componentProperties.staySummary?.roomNumberHeading,
                  }}
                >
                  {t('labels.room.roomNumber', { number: index + 1 })}
                </Heading>
              ) : null}
              <DataTable key={stay.operaId}>
                {/* If this booking has more than one room, render the PMS number for each room. */}
                {props.displayConfirmationNumber && isMultiRoomBooking ? (
                  <DataTableRow
                    label={t('labels.room.confirmationNumber')}
                    value={stay.operaId}
                    testId="confirmation-number"
                  />
                ) : undefined}
                <DataTableRow
                  label={t('labels.room.guests')}
                  value={
                    stay.roomGuestTypeLabelTranslationKey
                      ? getRoomOccupancyTypeTranslation(
                          stay.roomGuestTypeLabelTranslationKey
                        )
                      : undefined
                  }
                  testId="guest-occupancy"
                />
                <DataTableRow
                  label={t('labels.room.roomType')}
                  value={stay.firstRoomDetail.name}
                  testId="room-type"
                />
                {stay.firstRatePlan.rateCode === 'BAR' ? undefined : (
                  <DataTableRow
                    label={t('labels.room.rateCode')}
                    value={stay.firstRatePlan.rateTitle}
                    testId="room-rate-code"
                  />
                )}
                {brandConfig.showAveragePricePerNight &&
                  stay.numberOfNights > 1 && (
                    <BookingBreakdownPricePerNightTable
                      pricePerNightMode={props.pricePerNightMode}
                      stay={stay}
                    />
                  )}
                {showRoomTotalInBreakdown && (
                  <>
                    <DataTableRow
                      label={t('labels.room.total')}
                      testId="room-total"
                      value={
                        <CurrencyPreview
                          amount={stay.firstRatePlan.chargeBreakdown.getDisplayableTotalRoomRate(
                            hotel.legacyTaxes.isAdvanced
                          )}
                        />
                      }
                    />
                  </>
                )}
                {isSpecialAssistanceRequested && (
                  <>
                    <DataTableRow
                      label={t('labels.room.accessibleRoom')}
                      value={t('values.confirmed')}
                      testId="accessible-room"
                    />
                  </>
                )}
                {stay.firstRoomDetail.addOnsDetail?.map((addOn) => (
                  <DataTableRow
                    key={addOn.id}
                    testId={`addon-${addOn.id}`}
                    label={
                      addOn.calculatedQuantity > 1
                        ? `${addOn.name} (x${addOn.calculatedQuantity})`
                        : addOn.name
                    }
                    value={
                      <CurrencyPreview amount={addOn.cost.amountAfterTax} />
                    }
                  />
                ))}
              </DataTable>
            </div>
          </Container>
          {index < booking.roomStay.length - 1 && props.separator}
        </React.Fragment>
      ))}
    </>
  );
};

/**
 * Lists all taxes associated with the booking, in table row format.
 */
export const BookingBreakdownTaxTotalsTable = (props: BookingRequest) => {
  const booking = useBooking(props);
  const hotel = useHotel(props.hotelSlug);
  const { showTaxTotalInBreakdown } = useActiveBrandConfig();
  const { t: getTaxTranslation } = useTranslation('taxes');
  const { t: getTaxHelpTranslation } = useTranslation('taxesHelp');

  if (!showTaxTotalInBreakdown || !booking || !hotel) {
    return null;
  }

  return (
    <TaxTotals
      taxes={
        booking.firstTotalChargeBreakdown.costBreakdown?.taxesFees.map(
          (item) => ({
            name:
              item.name ||
              getTaxTranslation(item.humanTaxNameKey || 'otherTaxes'),
            amount: item.amount,
            // If a description is present, render that.
            // If not, attempt to resolve a translation string matching the code.
            helpText: item.description?.length
              ? item.description
              : item.helpTextKey
              ? getTaxHelpTranslation(item.helpTextKey)
              : item.name === 'Destination Fee' &&
                hotel.destinationFeeDescription
              ? hotel.destinationFeeDescription
              : '',
          })
        ) || []
      }
    />
  );
};

/**
 * A table data row containing the overall total for the booking.
 */
export const BookingBreakdownStayTotalsTable = (props: BookingRequest) => {
  const booking = useBooking(props);
  const hotel = useHotel(props.hotelSlug);
  const { t } = useTranslation('bookingSummary');

  if (!booking || !hotel) {
    return null;
  }

  return (
    <>
      <DataTableRow
        label={t('labels.total')}
        value={
          <CurrencyPreview
            amount={booking.firstTotalChargeBreakdown.grandTotal}
          />
        }
        testId="stay-total"
      />
    </>
  );
};

/**
 * A table data row containing the price per night for the booking.
 */
export const BookingBreakdownPricePerNightTable = (props: {
  stay: IRoomStayInstance;
  pricePerNightMode: BookingBreakdownRoomsTableProps['pricePerNightMode'];
}) => {
  const { t } = useTranslation('bookingSummary');
  const { stay, pricePerNightMode } = props;
  const padding = useSpaceMatchingKey('xxs');

  if (
    pricePerNightMode === 'actual' &&
    stay.firstRatePlan.chargeBreakdown.costDetail
  ) {
    return (
      <>
        <BodySmall>{t('labels.room.pricePerNight')}</BodySmall>
        <div
          role="rowgroup"
          css={css`
            padding-left: ${padding};
          `}
        >
          {stay.firstRatePlan.chargeBreakdown.costDetail.map(
            (costDetail, index) => (
              <DataTableRow
                key={index}
                label={`${t('labels.room.nightCount')} ${index + 1}`}
                testId="room-actual-nightly-price"
                value={
                  <CurrencyPreview amount={costDetail.cost.amountBeforeTax} />
                }
              />
            )
          )}
        </div>
      </>
    );
  }

  if (
    pricePerNightMode === 'average' &&
    stay.firstRatePlan.chargeBreakdown.averageNightlyRate
  ) {
    return (
      <DataTableRow
        label={t('labels.room.pricePerNight')}
        testId="room-average-nightly-price"
        value={
          <CurrencyPreview
            amount={stay.firstRatePlan.chargeBreakdown.averageNightlyRate}
          />
        }
      />
    );
  }
  return null;
};
