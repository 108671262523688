import { css } from '@emotion/react';
import dayjs from 'dayjs';
import localFont from 'next/font/local';
import Image from 'next/image';
import React from 'react';
import { useMedia } from 'react-use';

import {
  appendSearchQueryParamsToDisloyaltyUrl,
  getDisloyaltyBannerConfig,
  hasOptedForBlackFridaySale,
  transformHotelName,
} from '@/availability/components/banners/disloyalty-banner.helpers';
import { useAvailabilityURLQuery } from '@/availability/hooks';
import { Box } from '@/box';
import { useHotel } from '@/hotel';
import { BodySmall, Caption, Text } from '@/ui/typography';

const disloyaltyPPrightFont = localFont({
  src: '../../../../../public/disloyalty/fonts/pp-right-slab-light.woff2',
  display: 'swap',
});

const disloyaltySaansFont = localFont({
  src: '../../../../../public/disloyalty/fonts/Saans-Regular.woff2',
  display: 'swap',
});

export function DisloyaltyBanner({ hotelId }: { hotelId: string }) {
  const config = getDisloyaltyBannerConfig(hotelId);
  const hotel = useHotel(hotelId);

  const query = useAvailabilityURLQuery();
  const isMobile = useMedia(`(max-width: 639px)`);

  if (!config || !hotel) return null;

  const disloyaltyUrl = appendSearchQueryParamsToDisloyaltyUrl(
    config.link,
    query
  );

  const isSaleLive =
    hasOptedForBlackFridaySale(hotelId) &&
    query.successType === 'full' &&
    // bookings from 2024-11-25 to 2025-08-31
    dayjs(query.validData.from).isAfter(dayjs('2024-11-25')) &&
    dayjs(query.validData.to).isBefore(dayjs('2025-09-01')) &&
    // sale period from 2024-11-19 to 2024-12-04
    dayjs.utc().isAfter('2024-11-18T00:00:00Z') &&
    dayjs.utc().isBefore('2024-12-04T16:00:00Z');

  const logoType = isSaleLive ? '-sale-logo' : '-logo';
  const logoDimensions = isSaleLive ? [70, 98] : 78;

  const Description = () => {
    const baseText =
      'Booking must be made at least 7 days in advance. Discount cannot be used in conjunction with any other offers. T&Cs apply.';
    const saleText =
      'Sale booking must be made at least 7 days in advance, for trips between 26th Nov 2024 - 31st Aug 2025. Discount cannot be used in conjunction with any other offers. T&Cs apply.';

    return (
      <Box className={`${disloyaltyPPrightFont.className}`}>
        <Caption
          fontFamily="inherit"
          inheritColor
          textAlign={isMobile ? 'center' : 'left'}
        >
          {isSaleLive ? saleText : baseText}
        </Caption>
      </Box>
    );
  };

  return (
    <Box
      display={'flex'}
      position={'relative'}
      flexDirection={['column', 'row']}
      justifyContent={['start', 'space-between']}
      alignItems={'center'}
      backgroundColor={isSaleLive ? '#ff9f9a' : '#c5ff98'}
      padding={['20px', '10px 40px']}
      gap={20}
      css={{ color: '#272729' }}
      minHeight={148}
      className="fs-unmask"
      data-testid="disloyalty-banner"
    >
      <Box
        display={['flex']}
        justifyContent={'center'}
        alignItems={'center'}
        padding={[0, isSaleLive ? '15px 8px' : 0]}
        minWidth={logoDimensions}
        minHeight={logoDimensions}
        width={logoDimensions}
        height={logoDimensions}
        css={[
          css`
            box-sizing: content-box;
          `,
          !isSaleLive &&
            css`
              border: 1px solid #143c21;
              border-radius: 50%;
            `,
        ]}
      >
        <Image
          src={`/disloyalty/disloyalty${logoType}.svg`}
          width={isSaleLive ? 98 : 30}
          height={isSaleLive ? 98 : 33}
          alt="Disloyalty logo"
        />
      </Box>
      <Box
        marginRight={[0, 'auto']}
        textAlign={['center', 'left']}
        display={'flex'}
        flexDirection={'column'}
        gap={20}
      >
        <Box className={`${disloyaltySaansFont.className}`}>
          <Text
            style="heading5"
            uppercase
            fontFamily="inherit"
            css={{ lineHeight: '120%', color: '#272729', fontSize: '22px' }}
          >
            {`Want `}
            <u>
              {isSaleLive
                ? '50% off'
                : config.justOpened
                ? 'up to 50% off'
                : '20% off'}
            </u>
            {` your stay at ${transformHotelName(
              hotel.name,
              hotel.brandReferenceId
            )}?`}
          </Text>
        </Box>
        {!isMobile && <Description />}
      </Box>
      <Box
        className={`${disloyaltySaansFont.className}`}
        width={['100%', 'auto']}
      >
        <a
          href={disloyaltyUrl}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            padding: 24px 20px;
            color: #edeadd;
          `}
          rel="noopener noreferrer"
          target="_blank"
          data-test="disloyalty-banner-link"
        >
          <BodySmall uppercase noWrap inheritColor fontFamily="inherit">
            {'Join Dis-Loyalty now'}
          </BodySmall>
        </a>
      </Box>
      {isMobile && <Description />}
    </Box>
  );
}
