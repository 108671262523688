import { useAvailabilityURLQuery } from '@/availability';

export const getDisloyaltyBannerConfig = (hotelId: string) => {
  switch (hotelId) {
    case 'hoxton.holborn-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_holborn&utm_content=banner&slug=the-hoxton-holborn',
      };
    case 'hoxton.vienna-vienna':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_vienna&utm_content=banner&slug=the-hoxton-vienna',
      };
    case 'barcelona':
    case 'hoxton.poblenou-barcelona':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_barcelona&utm_content=banner&slug=the-hoxton-poblenou-barcelona',
      };
    case 'amsterdam':
    case 'hoxton.herengracht-amsterdam':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_amsterdamherengracht&utm_content=banner&slug=the-hoxton-amsterdam-herengracht',
      };
    case 'hoxton.chicago-chicago':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_chicago&utm_content=banner&slug=the-hoxton-chicago',
      };
    case 'hoxton.downtown-losangeles':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_dtla&utm_content=banner&slug=the-hoxton-downtown-la',
      };
    case 'hoxton.portland-portland':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_portland&utm_content=banner&slug=the-hoxton-portland',
      };
    case 'hoxton.williamsburg-newyork':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_williamsburg&utm_content=banner&slug=the-hoxton-williamsburg-new-york',
      };
    case 'hoxton.southwark-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_southwark&utm_content=banner&slug=the-hoxton-southwark',
      };
    case 'hoxton.lloyd-amsterdam':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_lloyd_amsterdam&utm_content=banner&slug=the-hoxton-lloyd-amsterdam',
      };
    case 'rome':
    case 'hoxton.rome-rome':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_rome&utm_content=banner&slug=the-hoxton-rome',
      };
    case 'paris':
    case 'hoxton.paris-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_paris&utm_content=banner&slug=the-hoxton-paris',
      };
    case 'hoxton.florence-florence':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_florence&utm_content=banner&slug=the-hoxton-florence',
      };
    case 'hoxton.shoreditch-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_shoreditch&utm_content=banner&slug=the-hoxton-shoreditch-london',
      };
    case 'shepherds-bush':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_shepherdsbush&utm_content=banner&slug=the-hoxton-shepherds-bush-london',
      };
    case 'hoxton.charlottenburg-berlin':
    case 'charlottenburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_berlin&utm_content=banner&slug=the-hoxton-charlottenburg',
      };
    case 'brussels':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=hoxton&utm_medium=website&utm_campaign=booking_brussels&utm_content=banner&slug=the-hoxton-brussels',
      };
    case '25hours.theroyalbavarian-munich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Royal-Bavarian-booking&slug=25-hours-hotel-munich-the-royal-bavarian',
      };
    case '25hours.piazzasanpaolino-florence':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Piazza-San-Paolino-booking&slug=25-hours-hotel-piazza-san-paolino',
      };
    case '25hours.west-zurich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Zurich-West-booking&slug=25hours-hotel-zuerich-west',
      };
    case '25hours.beimmuseumquartier-vienna':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Museumsquartier-booking&slug=25hours-hotel-vienna-at-museums-quartier',
      };
    case '25hours.thecircle-cologne':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Circle-booking&slug=25hours-hotel-cologne-the-circle',
      };
    case '25hours.langstrasse-zurich':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Zurich-Langstrasse-booking&slug=25-hours-hotel-zurich-langstrasse',
      };
    case '25hours.dastour-dusseldorf':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Tour-booking&slug=25hours-hotel-duesseldorf-das-tour',
      };
    case '25hours.thegoldman-frankfurt':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Goldman-booking&slug=25hours-hotel-frankfurt-the-goldman',
      };
    case '25hours.thetrip-frankfurt':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=The-Trip-booking&slug=25hours-hotel-frankfurt-the-trip',
      };
    case '25hours.alteshafenamt-hamburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Altes-Hafenamt-booking&slug=25hours-hotel-hamburg-altes-hafenamt',
      };
    case '25hours.hafencity-hamburg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Hafen-City-booking&slug=25-hours-hotel-hamburg-hafen-city',
      };
    case '25hours.bikiniberlin-berlin':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=Bikini-Berlin-booking&slug=25-hours-hotel-bikini-berlin',
      };
    case '25hours.paperisland-copenhagen':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_source=25hours&utm_medium=website&utm_campaign=paper-island-booking&slug=25hours-hotel-copenhagen-paper-island',
      };
    case '25hours.terminusnord-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_source=25hours&utm_medium=website&utm_campaign=paris-booking&slug=25hours-hotel-paris-terminus-nord',
      };
    case 'morgansoriginals.sanderson-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=morgansoriginals&utm_medium=website&utm_campaign=sanderson-london-booking&slug=sanderson-london',
      };
    case 'morgansoriginals.st-martins-lane-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=morgansoriginals&utm_medium=website&utm_campaign=st-martins-lane-london-booking&slug=st-martins-lane-london',
      };
    case 'mamashelter.east-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-east-booking&slug=mama-shelter-paris-east',
      };
    case 'mamashelter.toulouse-toulouse':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=toulouse-booking&slug=mama-shelter-toulouse',
      };
    case 'mamashelter.roma-rome':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=roma-booking&slug=mama-shelter-roma',
      };
    case 'mamashelter.rennes-rennes':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=rennes-booking&slug=mama-shelter-rennes',
      };
    case 'mamashelter.prague-prague':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=prague-booking&slug=mama-shelter-prague',
      };
    case 'mamashelter.west-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-west-booking&slug=mama-shelter-paris-west',
      };
    case 'mamashelter.ladefense-paris':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=paris-la-defense-booking&slug=mama-shelter-paris-la-defense',
      };
    case 'mamashelter.marseille-marseille':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=marseille-booking&slug=mama-shelter-marseille',
      };
    case 'mamashelter.lyon-lyon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lyon-booking&slug=mama-shelter-lyon',
      };
    case 'mamashelter.luxembourg-luxembourg':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=luxembourg-booking&slug=mama-shelter-luxembourg',
      };
    case 'mamashelter.losangeles-losangeles':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=los-angeles-booking&slug=mama-shelter-los-angeles',
      };
    case 'mamashelter.shoreditch-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=london-booking&slug=mama-shelter-london',
      };
    case 'mamashelter.lisboa-lisbon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lisboa-booking&slug=mama-shelter-lisboa',
      };
    case 'mamashelter.lille-lille':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=lille-booking&slug=mama-shelter-lille',
      };
    case 'mamashelter.dijon-dijon':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=dijon-booking&slug=mama-shelter-dijon',
      };
    case 'mamashelter.bordeaux-bordeaux':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=bordeaux-booking&slug=mama-shelter-bordeaux',
      };
    case 'mamashelter.belgrade-belgrade':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_source=mamashelter&utm_medium=website&utm_campaign=belgrade-booking&slug=mama-shelter-belgrade',
      };
    case 'mamashelter.nice-nice':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_source=mamashelter&utm_medium=website&utm_campaign=nice-booking&slug=mama-shelter-nice',
      };
    case 'hyde.ibiza-ibiza':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=hyde&utm_campaign=booking-ibiza&slug=hyde-ibiza',
      };
    case 'hyde.londoncity-london':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=hyde&utm_campaign=booking-london-city&slug=hyde-london-city',
      };
    case 'mondrian.ibiza-ibiza':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=mondrian&utm_campaign=booking-ibiza&slug=mondrian-ibiza',
      };
    case 'mondrian.lescarmes-bordeaux':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=mondrian&utm_campaign=booking-bordeaux-les-carmes&slug=mondrian-bordeaux-les-carmes',
      };
    case '21c.museumhotel-bentonville':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-bentonville&slug=21c-bentonville',
      };
    case '21c.museumhotel-chicago':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-chicago&slug=21c-chicago',
      };
    case '21c.museumhotel-cincinnati':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-cincinatti&slug=21c-cincinnati',
      };
    case '21c.museumhotel-durham':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-durham&slug=21c-durham',
      };
    case '21c.museumhotel-kansascity':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-kansas-city&slug=21c-kansas-city',
      };
    case '21c.museumhotel-lexington':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-lexington&slug=21c-lexington',
      };
    case '21c.museumhotel-louisville':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-louisville&slug=21c-louisville',
      };
    case '21c.museumhotel-stlouis':
      return {
        justOpened: false,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=21c&utm_campaign=booking-st-louis&slug=21c-st-louis',
      };
    case 'sls.barcelona-barcelona':
      return {
        justOpened: true,
        link: 'https://dis-loyalty.com/from-brand?utm_content=banner&utm_medium=website&utm_source=sls&utm_campaign=booking-barcelona&slug=sls-barcelona',
      };
    default:
      return null;
  }
};
export const transformHotelName = (
  hotelName: string,
  brandReferenceId: string
) => {
  switch (brandReferenceId) {
    case '25hours':
      return hotelName.replace(/(?!25hours\b)\b\w+/gm, (substring) =>
        substring.toUpperCase()
      );
    default:
      return hotelName.toUpperCase();
  }
};
export const appendSearchQueryParamsToDisloyaltyUrl = (
  url: string,
  query: ReturnType<typeof useAvailabilityURLQuery>
) => {
  // Proceed to include search params only if the query is fully valid
  if (query?.successType !== 'full') return url;

  const newUrl = new URL(url);

  // Transform our query to Disloyalty's format
  const transformedSearchQuery = {
    checkInDate: query.validData.from,
    checkOutDate: query.validData.to,
    adults: query.validData.rooms
      .reduce((count, roomOccupancy) => count + roomOccupancy.adults, 0)
      .toFixed(),
    children: query.validData.rooms
      .reduce((count, roomOccupancy) => count + roomOccupancy.children, 0)
      .toFixed(),
  };

  // Append query params
  for (const [name, value] of Object.entries(transformedSearchQuery)) {
    newUrl.searchParams.append(name, value);
  }

  return newUrl.href;
};

/**
 * Check if the hotel has opted for the Black Friday sale
 * @note This can be deleted after the sale is over, which is on 2024-12-04.
 */
export const hasOptedForBlackFridaySale = (hotelId: string) => {
  switch (hotelId) {
    case 'hoxton.lloyd-amsterdam':
    case 'brussels':
    case 'charlottenburg':
    case 'hoxton.charlottenburg-berlin':
    case 'hoxton.downtown-losangeles':
    case 'hoxton.poblenou-barcelona':
    case 'hoxton.portland-portland':
    case 'hoxton.rome-rome':
    case 'shepherds-bush':
    case 'hoxton.shoreditch-london':
    case 'hoxton.southwark-london':
    case 'hoxton.vienna-vienna':
    case '25hours.paperisland-copenhagen':
    case '25hours.piazzasanpaolino-florence':
    case '25hours.terminusnord-paris':
    case 'hyde.ibiza-ibiza':
    case 'hyde.londoncity-london':
    case '21c.museumhotel-bentonville':
    case '21c.museumhotel-chicago':
    case '21c.museumhotel-cincinnati':
    case '21c.museumhotel-durham':
    case '21c.museumhotel-kansascity':
    case '21c.museumhotel-lexington':
    case '21c.museumhotel-louisville':
    case '21c.museumhotel-stlouis':
    case 'mondrian.ibiza-ibiza':
    case 'mondrian.lescarmes-bordeaux':
    case 'sls.barcelona-barcelona':
    case 'morgansoriginals.st-martins-lane-london':
    case 'morgansoriginals.sanderson-london':
      return true;
    default:
      return false;
  }
};
