import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'Marr Sans, sans-serif',
  intro: 'PortraitText, Times',
  subheader: 'PortraitText, sans-serif',
  body: 'PortraitText, sans-serif',
};

export const townhouseTheme: EnnismoreTheme = {
  themeKey: 'townhouse',
  logoUrl: '/brands/townhouse/images/logo.svg',
  faviconUrl: '/brands/townhouse/favicons/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '16px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#4A4A4A',
    textSecondary: '#908D8A',
    background: '#FBF2E9',
    primary100: '#F5F6FF',
    primary200: '#EAEBFF',
    primary300: '#B6B9E9',
    primary400: '#787ECF',
    primary500: '#434892',
    secondary100: '#F6F6F6',
    secondary200: '#D9D9D9',
    secondary300: '#989898',
    secondary400: '#5B6661',
    error: '#B00020',
  },
  keylines: {
    border: '1px solid #B6B9E9',
  },
  buttons: {
    primary: {
      fontFamily: 'Marr Sans, sans-serif',
      backgroundColor: '#1D1D1D',
      fontWeight: 400,
    },
    secondary: {
      fontFamily: 'Marr Sans, sans-serif',
      border: '1px solid #1D1D1D',
      color: '#1D1D1D',
      backgroundColor: 'transparent',
      fontWeight: 400,
    },
  },
  forms: {
    input: {},
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'PortraitText',
      fontWeight: 300,
      fontStyle: 'normal',
      sources: {
        woff: '/brands/townhouse/fonts/PortraitText-Regular-Web.woff',
        woff2: '/brands/townhouse/fonts/PortraitText-Regular-Web.woff2',
      },
    },
    {
      fontFamily: 'PortraitText',
      fontWeight: 300,
      fontStyle: 'italic',
      sources: {
        woff: '/brands/townhouse/fonts/PortraitText-RegularItalic-Web.woff',
        woff2: '/brands/townhouse/fonts/PortraitText-RegularItalic-Web.woff2',
      },
    },
    {
      fontFamily: 'Work Sans',
      fontWeight: 400,
      sources: {
        woff: 'https://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8JoI3ZKyHaQQ.woff',
      },
    },
    {
      fontFamily: 'Marr Sans',
      fontWeight: 400,
      sources: {
        woff2: '/brands/townhouse/fonts/MarrSans-Medium.woff2',
        woff: '/brands/townhouse/fonts/MarrSans-Medium.woff',
      },
    },
  ],
  componentProperties: {
    search: {
      results: {
        roomSpecColumns: 1,
      },
    },
    sidebarCards: {
      primary: {
        borderRadius: '0px',
        backgroundColor: 'primary100',
        borderColor: 'primary300',
        hideBorder: true,
      },
      secondary: {
        borderRadius: '8px',
        padding: 'l',
        borderColor: 'primary300',
      },
    },
    banners: {
      backgroundColor: 'primary500',
      color: 'white',
    },
  },
  icons: {
    BULLET: '/brands/townhouse/icons/townhouse-dash.svg',
  },
  __temporary: {
    displayCarTransportOnNewLine: false,
  },
};
