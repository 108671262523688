import {
  V1CreateBookingRequest,
  V1GetAvailableAddOnsRequest,
  V1GetBookingRequest,
  V1UpdateBookingAddOnsRequest,
} from '@ennismore/booking-api-client';
import { z } from 'zod';

import { OHIPBookingAPIBaseService } from '@/api/clients/ohip';
import {
  v1GetAvailableAddOnsResponseSchema,
  v1GetBookingResponseSchema,
  v1UpdateBookingAddOnsResponseSchema,
} from '@/api/clients/ohip/models';
import {
  BookingAddOnModel,
  IBookingAddOnInstance,
} from '@/booking-add-ons/models';
import { IBookingSnapshot } from '@/booking/models';
import { getDayTimePartFromStartHour } from '@/flexy-time';
import { getBookingGuestFromCount } from '@/hotel';
import { getLanguageFromLocale } from '@/i18n/utils';

import {
  BookingAddOnsError,
  BookingError,
  CreateBookingError,
  UpdateBookingAddOnsError,
} from '../errors';
import { BookingServiceClient } from './common';

// @TODO: Clean this up
export class OHIPBookingServiceClient
  extends OHIPBookingAPIBaseService
  implements BookingServiceClient
{
  async getBooking(
    args: V1GetBookingRequest & { locale: { language: string } }
  ) {
    try {
      const response = await this.client.bookingApiServiceGetBooking(
        getLanguageFromLocale(args.locale.language) || 'en',
        {
          ...args,
          // TODO[DEV-4829]: revert back to using full locale when it'll be supported
        },
        this.options
      );

      // Validate response
      const parsed = v1GetBookingResponseSchema.safeParse(response.data);

      // ZodErrors are getting swallowed - temporarily manually log this out
      if (!parsed.success) {
        console.log(
          'GetBooking returned an unexpected response',
          parsed.error.message
        );

        throw parsed.error;
      }
      const booking = parsed.data;

      // Create a base 'amount' containing currency + decimal values from a known property to help fill defaults for optional fields (tax)
      const baseAmount = { ...booking.totalCost.amountAfterTax };

      // Map new OHIP model (booking) to legacy OWS booking structure (mapped)
      // NOTE: This is a pretty hefty WIP!
      const mapped: IBookingSnapshot = {
        id: booking.bookingId,
        metaData: {
          flexyCheckInOutAvailable:
            booking.rooms[0].additionalInformation?.flexyTimeAvailable,
        },
        checkin: getDayTimePartFromStartHour(
          booking.rooms[0].additionalInformation?.flexyTime?.checkInStart
        ),
        checkout: getDayTimePartFromStartHour(
          booking.rooms[0].additionalInformation?.flexyTime?.checkOutStart
        ),
        // There is one total charge breakdown per booking
        totalChargeBreakdown: [
          {
            rateCode: {
              requestRateCode: booking.rooms[0].rate.code,
            },
            charity: undefined, // TBD
            costBreakdown: {
              taxesFees:
                booking.totalCost.tax?.detail.map((detail) => ({
                  amount: detail.amount,
                  tax: undefined,
                  name: detail.name,
                  description: detail.description,
                })) || [], // @TODO fix this - is it really optional?
              totalRoomRate: booking.totalCost.amountSubtotalDisplay,
              totalTaxesFee: booking.totalCost.tax?.amount || {
                ...baseAmount,
                value: 0,
              },
            },
            grandTotal: booking.totalCost.amountAfterTax,
            deposit: booking.totalDeposit?.amount,
            isCalendarAvailable: false,
            containsFees: booking.rooms[0].rate.containsFees,
          },
        ],
        roomStay: booking.rooms.map((reservation) => ({
          from: reservation.checkInDate,
          to: reservation.checkOutDate,
          operaId: reservation.pmsId,
          status: booking.status,
          profile: {
            id: '', // @TODO do we need this?
            firstName: reservation.guestProfile.firstName,
            lastName: reservation.guestProfile.lastName,
            email: reservation.guestProfile.contactDetails.emailAddress,
          },
          guestProfiles: reservation.guestProfiles?.map((profile) => ({
            id: '', // @TODO do we need this?
            firstName: profile.firstName,
            lastName: profile.lastName,
            email: profile.contactDetails.emailAddress,
            phone: profile.contactDetails.phoneNumber,
            type: profile.type,
          })),
          ratePlan: [
            {
              chargeBreakdown: {
                grandTotal: reservation.rate.cost.amountAfterTax,
                rateCode: { requestRateCode: reservation.rate.code },
                costBreakdown: {
                  // @TODO Have had to clear this as TRIBE was returning "19" which doesn't match the standard enum set
                  taxesFees:
                    reservation.rate.cost.tax?.detail.map((tax) => ({
                      // `tax` in this context refers to the legacy "tax type" enum, for OHIP we're passing translated name/description instead
                      tax: undefined,
                      amount: tax.amount,
                      name: tax.name,
                      description: tax.description,
                    })) || [],
                  totalRoomRate: reservation.rate.cost.amountSubtotalDisplay,
                  totalTaxesFee: reservation.rate.cost.tax?.amount || {
                    ...baseAmount,
                    value: 0,
                  }, // @TODO Remove this before production. This is literally just to test. Tax shouldn't be null.
                },
                averageNightlyRate:
                  reservation.rate.averageNightlyAmountAfterTax,
                costDetail: reservation.rate.costDetail,
                containsFees: reservation.rate.containsFees,
              },
              rateCode: reservation.rate.code,
              rateDescription: [reservation.rate.description],
              rateName: reservation.rate.name,
              cancelPolicy: undefined, // @TODO Remove this field altogether, unused
              taxInformation: undefined,
              deposit: undefined, // @TODO Unsure if needed
              depositPolicy: undefined, // @TODO Unsure if needed
            },
          ],
          roomDetail: [
            {
              rateCode: reservation.rate.code,
              code: reservation.room.code,
              currencyCode: reservation.rate.cost.amountAfterTax.currencyCode,
              images: reservation.room.images.map((image) => ({
                id: image.typeId,
                altText: image.altText,
                source: image.url,
              })),
              name: reservation.room.name,
              rateCost: reservation.rate.cost.amountAfterTax, // @TODO should be flipping these if needed for appropriate countries (vat inclusive etc)
              addOns: {
                detail: reservation.addOns?.detail.map((addOn) => ({
                  ...addOn,
                  cost: {
                    ...addOn.cost,
                    taxesFees: {
                      amount: addOn.cost.tax?.amount,
                      detail: addOn.cost.tax?.detail.map((tax) => ({
                        tax: undefined,
                        name: tax.name,
                        amount: tax.amount,
                        description: tax.description,
                      })),
                    },
                  },
                })),
                cost: reservation.addOns?.cost
                  ? {
                      ...reservation.addOns?.cost,
                      taxesFees: {
                        amount: reservation.addOns?.cost?.tax?.amount,
                        detail: reservation.addOns?.cost?.tax?.detail.map(
                          (tax) => ({
                            tax: undefined,
                            name: tax.name,
                            description: tax.description,
                            amount: tax.amount,
                          })
                        ),
                      },
                    }
                  : undefined,
              },
            },
          ],
          roomGuest: getBookingGuestFromCount(reservation.guests),
        })),
        status: booking.status,
        costConfirmed: booking.costConfirmed,
        payment: {
          secret: booking.payment.clientSecret,
          method: booking.payment.method,
          connectedAccountId: booking.payment.connectedAccountId,
        },
      };

      return mapped;
    } catch (e) {
      throw new BookingError(this.parseError(e));
    }
  }
  async createBooking(
    args: V1CreateBookingRequest & { locale: { language: string } }
  ) {
    try {
      const response = await this.client.bookingApiServiceCreateBooking(
        // TODO: why is this needed?
        'user-agent',
        getLanguageFromLocale(args.locale.language) || 'en',
        {
          ...args,
          // TODO[DEV-4829]: revert back to using full locale when it'll be supported
        },
        this.options
      );
      return z.object({ bookingId: z.string() }).parse(response.data);
    } catch (e) {
      throw new CreateBookingError(this.parseError(e));
    }
  }
  async getAvailableAddOns(
    args: V1GetAvailableAddOnsRequest & { locale: { language: string } }
  ) {
    try {
      const response = await this.client.bookingApiServiceGetAvailableAddOns(
        getLanguageFromLocale(args.locale.language) || 'en',
        {
          ...args,
          // TODO[DEV-4829]: revert back to using full locale when it'll be supported
        },
        this.options
      );
      const addOns = v1GetAvailableAddOnsResponseSchema.parse(
        response.data
      ).addOns;

      const mapped: IBookingAddOnInstance[] = addOns.map((addOn) =>
        BookingAddOnModel.parse({
          ...addOn,
          cost: {
            ...addOn.cost,
            taxesFees: {
              amount: addOn.cost.tax?.amount,
              detail: addOn.cost.tax?.detail.map((tax) => ({
                tax: undefined,
                amount: tax.amount,
              })),
            },
          },
        })
      );

      return mapped;
    } catch (e) {
      throw new BookingAddOnsError(this.parseError(e));
    }
  }
  async updateBookingAddOns(args: V1UpdateBookingAddOnsRequest) {
    try {
      const response = await this.client.bookingApiServiceUpdateBookingAddOns(
        'en',
        args,
        this.options
      );
      return v1UpdateBookingAddOnsResponseSchema.parse(response.data);
    } catch (e) {
      throw new UpdateBookingAddOnsError(this.parseError(e));
    }
  }
}
