import * as Sentry from '@sentry/react';
// Polyfill for :focus-visible, adds a .focus-visible class to the focused element.
import 'focus-visible';
import log from 'loglevel';
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import React, { useEffect } from 'react';
// import 'react-dates/lib/css/_datepicker.css';
// IMPORTANT: We must import all CSS files here. Doing so elsewhere will break Router.push('/').
// See: https://github.com/zeit/next-plugins/issues/282
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
// modules styles
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css';
// swiper core styles
import 'swiper/swiper.min.css';

import { GTMProvider } from '@/analytics';
import { FullStoryEventsProvider } from '@/analytics/components/FullStoryEventsProvider.component';
import { EnnismoreApiProvider } from '@/api';
import { AuthProvider } from '@/auth';
import { usePersistBookingConfigurations } from '@/booking-checkout';
import {
  BrandConfiguration,
  BrandConfigurationSchema,
  BrandProvider,
} from '@/brand';
import { fetchBrandConfigForNextInitialProps } from '@/brand/common/services';
import { CanaryProvider } from '@/canary';
import { NoSSR } from '@/common/components/utils';
import { setupClientSideSentry } from '@/common/scripts/sentry';
import { CookieConsentScript } from '@/cookie-consent';
import { CookieConsentSideEffects } from '@/cookie-consent/CookieConsentSideEffects.component';
import { CookieConsentStatusProvider } from '@/cookie-consent/CookieConsentStatusProvider.component';
// Setup global scripts
// import '@/common/scripts/accessibility';
import { getEnvironmentKey, getEnvironmentVars } from '@/env';
import { FeatureSwitchProvider } from '@/features/components/FeatureSwitchProvider.component';
import FeatureSwitchUIModalContainer from '@/features/components/FeatureSwitchUIModal.container';
import { HypertuneFeatureFlagProvider } from '@/flags/hypertune/provider';
import { LocaleProvider } from '@/i18n';
import { trpc } from '@/trpc/client';
import { Favicons } from '@/ui/favicon';
import { ThemeSwitcherProvider } from '@/ui/theme';
import { CssReset } from '@/ui/utils';

setupClientSideSentry();

// Capture web vitals fron Next.js
export function reportWebVitals(metric: NextWebVitalsMetric) {
  // Disable for now until LaunchDarkly integration. @TODO place this behind a flag.
  // return trpcClient.telemetry.reportVitals.mutate(metric);
}

const queryClient = new QueryClient();

if (process.browser && getEnvironmentKey() === 'local') {
  log.setDefaultLevel('DEBUG');
}

const apiConfig = {
  apiKey: getEnvironmentVars().NEXT_PUBLIC_API_KEY,
  baseUrl: getEnvironmentVars().NEXT_PUBLIC_API_BASE_URL,
};

interface BookingAppProps {
  brandConfig: BrandConfiguration;
}

export const BookingApp: React.FC<AppProps<BookingAppProps>> = (props) => {
  const { pageProps, Component } = props;
  const { brandConfig } = pageProps;

  usePersistBookingConfigurations();

  useEffect(() => {
    Modal.setAppElement('#__next');
  }, []);

  const parsed = BrandConfigurationSchema.safeParse(brandConfig);

  // @TODO Improve this error handling - render 404
  if (!parsed.success) {
    throw parsed.error;
  }

  return (
    <>
      <Head>
        <title>{`Bookings | ${brandConfig.name}`}</title>
        {brandConfig.analytics?.cookieConsent && (
          <CookieConsentScript config={brandConfig.analytics.cookieConsent} />
        )}
      </Head>

      <BrandProvider brandConfig={parsed.data}>
        <CanaryProvider>
          <QueryClientProvider client={queryClient}>
            <CookieConsentStatusProvider
              config={brandConfig.analytics?.cookieConsent}
            >
              <FeatureSwitchProvider>
                <LocaleProvider>
                  <NoSSR>
                    <AuthProvider>
                      <EnnismoreApiProvider config={apiConfig}>
                        <GTMProvider>
                          <FullStoryEventsProvider>
                            <ThemeSwitcherProvider>
                              <HypertuneFeatureFlagProvider>
                                <>
                                  <Component {...pageProps} />
                                  <FeatureSwitchUIModalContainer />
                                  <Favicons />
                                  {brandConfig.analytics?.cookieConsent ? (
                                    <CookieConsentSideEffects
                                      config={
                                        brandConfig.analytics.cookieConsent
                                      }
                                    />
                                  ) : undefined}
                                </>
                              </HypertuneFeatureFlagProvider>
                            </ThemeSwitcherProvider>
                          </FullStoryEventsProvider>
                        </GTMProvider>
                      </EnnismoreApiProvider>
                    </AuthProvider>
                  </NoSSR>
                  <CssReset />
                </LocaleProvider>
              </FeatureSwitchProvider>
            </CookieConsentStatusProvider>
          </QueryClientProvider>
        </CanaryProvider>
      </BrandProvider>
    </>
  );
};

(BookingApp as any).getInitialProps = async (context: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(context);

  const brandConfig = await fetchBrandConfigForNextInitialProps(context);

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      brandConfig,
    },
  };
};

export default Sentry.withProfiler(trpc.withTRPC(BookingApp));
