import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'dtl-fleischmann, sans-serif',
  intro: 'dtl-fleischmann, Times',
  subheader: 'dtl-fleischmann, sans-serif',
  body: 'dtl-fleischmann, sans-serif',
};

export const estelleTheme: EnnismoreTheme = {
  themeKey: 'estelle',
  logoUrl: '/brands/estelle/images/logo.svg',
  faviconUrl: '/brands/estelle/images/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 200,
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '16px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '20px',
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#333',
    textSecondary: '#656565',
    background: '#faf5f2',
    primary100: '#FEF9F3', // glen only - ignore
    primary200: '#dedede',
    primary300: '#999',
    primary400: '#FFE7D9', // hox only
    primary500: '#333',
    secondary100: '#fff',
    secondary200: '#999',
    secondary300: '#333',
    secondary400: '#333',
    error: '#B00020',
  },
  buttons: {
    primary: {
      backgroundColor: '#333',
      fontFamily: 'dtl-fleischmann, serif',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    secondary: {
      backgroundColor: 'transparent',
      border: '1px solid #333',
      color: '#333',
      fontFamily: 'dtl-fleischmann, serif',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontSize: '14px',
    },
  },
  keylines: {
    border: '0.5px solid #999',
  },
  forms: {
    input: {},
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'dtl-fleischmann',
      fontWeight: 400,
      sources: {
        woff: '/brands/estelle/fonts/dtl-fleischmann-st-regular.woff2',
      },
    },
  ],
  componentProperties: {
    sidebarCards: {
      primary: {
        borderRadius: '0px',
        backgroundColor: 'white',
        borderColor: 'white',
      },
      secondary: {
        borderRadius: '4px',
        borderColor: 'primary300',
      },
    },
    banners: {
      backgroundColor: 'white',
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
